<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row v-if="isAdminView">
            <md-table-head v-for="(col, index) in colTablAdmin" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>

          <md-table-row v-if="isPathologistView">
            <md-table-head v-for="(col, index) in colTabPathologist" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in personaliseReportData"
            :key="`personaliseReportData-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell>{{ item.title }}</md-table-cell>
            <md-table-cell>{{ item.shortcut }}</md-table-cell>
            <md-table-cell v-if="isAdminView">{{ item.pathologistName }}</md-table-cell>
            <md-table-cell>
              <div>{{ item.snomedMFieldItem ? 'M: ' + item.snomedMFieldItem.fieldItemName : '' }}</div>
              <div>{{ item.snomedPFieldItem ? 'P: ' + item.snomedPFieldItem.fieldItemName : '' }}</div>
              <div>{{ item.snomedTFieldItem ? 'T: ' + item.snomedTFieldItem.fieldItemName : '' }}</div>
            </md-table-cell>
            <md-table-cell>{{ item.hasButtonAssigned ? 'Y' : '' }}</md-table-cell>
            <md-table-cell>
              <md-icon :class="item.status === 1 ? 'status-enabled' : 'status-disabled'">adjust</md-icon>
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div v-if="isShowViewIcon(item)" style="width: 25%">
                  <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view', item, $event)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowEditIcon(item)" style="width: 25%">
                  <md-button
                    class="md-just-icon md-warning md-simple"
                    @click.native="handleAction('edit', item, $event)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowDeleteIcon(item)" style="width: 25%">
                  <md-button
                    class="md-just-icon md-danger md-simple"
                    @click.stop="handleAction('delete', item, $event)"
                  >
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowButtonIcon(item)" style="width: 25%">
                  <md-button class="md-just-icon md-simple" @click.native="handleAction('edit-button', item, $event)">
                    <md-icon>note</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { PERSONALISED_REPORT } from '@/core/constants';

export default {
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },
  props: {
    personaliseReportData: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTablAdmin: [
        {
          name: 'pages/personalised-report/list/title',
          key: 'title',
          sort: false,
          class: 'title-column',
        },
        {
          name: 'pages/personalised-report/list/shortcut',
          key: 'shortcut',
          sort: false,
          class: 'shortcut-column',
        },
        {
          name: 'pages/personalised-report/list/pathologist',
          key: 'pathologist',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages/personalised-report/list/snomed',
          key: 'snomed',
          sort: false,
          class: 'snomed-column',
        },
        {
          name: 'pages/personalised-report/list/button',
          key: 'button',
          sort: false,
          class: 'button-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      colTabPathologist: [
        {
          name: 'pages/personalised-report/list/title',
          key: 'title',
          sort: false,
          class: 'title-column',
        },
        {
          name: 'pages/personalised-report/list/shortcut',
          key: 'shortcut',
          sort: false,
          class: 'shortcut-column',
        },
        {
          name: 'pages/personalised-report/list/snomed',
          key: 'snomed',
          sort: false,
          class: 'snomed-column',
        },
        {
          name: 'pages/personalised-report/list/button',
          key: 'button',
          sort: false,
          class: 'button-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
    };
  },
  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
  },
  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },
    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },
    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
    isShowViewIcon() {
      return this.$isAuthorized(['Case_PersonalizedReports_Retrieve']);
    },
    isShowEditIcon() {
      return this.$isAuthorized(['Case_PersonalizedReports_Update']);
    },
    isShowDeleteIcon(item) {
      return this.$isAuthorized(['Case_PersonalizedReports_Delete']) && item.status === PERSONALISED_REPORT.Disabled;
    },
    isShowButtonIcon(item) {
      return this.$isAuthorized(['Case_PersonalizedReports_Update']) && item.hasButtonAssigned;
    },
  },
};
</script>
<style lang="scss"></style>
