<template>
  <lims-block :cardText="false" class="list-page">
    <modal-select-pathologist
      ref="selectPathologistModal"
      slot="blockTitle"
      @onSelect="onSelectPathologist"
    ></modal-select-pathologist>
    <modal-delete-personalise-report
      ref="deletePersonaliseReportModal"
      slot="blockTitle"
      @onDelete="onConfirmDeletePersonaliseReport"
      :personalisedReportTitle="selectedItem.title"
    ></modal-delete-personalise-report>
    <modal-confirm-delete-pr-button ref="confirmDeletePrButtonModal" slot="blockTitle"></modal-confirm-delete-pr-button>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <personalised-report-list @action="onRowAction" :personalise-report-data="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <md-field class="field-btn">
                <md-button @click="onAssignButton" class="assign-button">{{
                  $t('pages/personalised-report/button.assignButton')
                }}</md-button>
                <md-button @click="onPersonalisedReport">{{
                  $t('pages/personalised-report/button.addPersonalisedReport')
                }}</md-button>
              </md-field>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <personalised-report-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></personalised-report-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </personalised-report-list>

        <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import PersonalisedReportList from './List/PersonalisedReportList.vue';
import PersonalisedReportFilter from './List/PersonalisedReportFilter.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { personaliseReport } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import cloneDeep from 'lodash/cloneDeep';
import { APP_ROUTES, FORM_ACTIONS } from '@/core/constants';
import ModalSelectPathologist from '@/components/Lims/modals/ModalSelectPathologist';
import { mapGetters } from 'vuex';
import { PersonalizedReportService } from '@/services';
import ModalDeletePersonaliseReport from '@/components/Lims/modals/ModalDeletePersonaliseReport';
import ModalConfirmDeletePrButton from '@/components/Lims/modals/ModalConfirmDeletePrButton';

export default {
  mixins: [ConfigMixins, ListWithFilterMixins],
  components: {
    ModalConfirmDeletePrButton,
    ModalDeletePersonaliseReport,
    ModalSelectPathologist,
    PersonalisedReportList,
    PersonalisedReportFilter,
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.fetchData();
    this.userType = this.$store.getters['auth/userType'];
  },
  data() {
    return {
      snomedMOption: [],
      snomedPOption: [],
      snomedTOption: [],
      items: [],
      totalItem: null,
      query: null,
      selectedItem: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    ...mapGetters('auth', ['user']),
    fields() {
      return personaliseReport.filters.fields;
    },
    defaultValues() {
      return personaliseReport.filters.defaultValues;
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isPathView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
  },
  methods: {
    async fetchData() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(personaliseReport.filters, queryParams);
      const pagination = buildFilterFromQuery(personaliseReport.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(personaliseReport.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await PersonalizedReportService.getPersonalisedReportList({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: null,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.personalizedReports.items;
      this.totalItem = data.personalizedReports.pagination.totalItems;
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(personaliseReport.filters.defaultValues);
    },
    onRowAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow(item);
      }
      if (action === FORM_ACTIONS.EDIT_BUTTON) {
        this.editButton(item);
      }
    },
    onAssignButton() {
      if (this.isAdminView) {
        this.$refs.selectPathologistModal.open();
      } else {
        if (this.isPathView) {
          const pathologistFullName = this.user.firstName + ' ' + this.user.lastName;
          this.$router.push({
            name: 'PathologistAssignButton',
            params: {
              pathologistId: this.userId,
              pathologistName: pathologistFullName,
            },
          });
        }
      }
    },
    onSelectPathologist(formData) {
      if (formData.selectOption) {
        this.$router.push({
          name: 'PathologistAssignButton',
          params: {
            pathologistId: formData.selectOption.id,
            pathologistName: formData.selectOption.text,
          },
        });
        this.$refs.selectPathologistModal.close();
      }
    },
    onPersonalisedReport() {
      this.$router.push({
        name: 'PersonalisedReportAdd',
      });
    },
    editRow(item) {
      this.$router.push(`${APP_ROUTES.PERSONALISED_REPORT_EDIT}/${item.casePersonalizedReportId}`);
    },
    viewRow(item) {
      this.$router.push(`${APP_ROUTES.PERSONALISED_REPORT_VIEW}/${item.casePersonalizedReportId}`);
    },
    deleteRow(item) {
      if (item.hasButtonAssigned) {
        this.$refs.confirmDeletePrButtonModal.open();
      } else {
        this.selectedItem = item;
        this.$refs.deletePersonaliseReportModal.open();
      }
    },
    async onConfirmDeletePersonaliseReport() {
      this.$refs.deletePersonaliseReportModal.close();
      const { data, error } = await PersonalizedReportService.deletePersonalizedReport(
        this.selectedItem.casePersonalizedReportId,
      );
      if (error) {
        return this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('pages/personalised-report/form/alertSuccess/delete', {
              title: this.selectedItem.title,
            }),
          );
          setTimeout(async () => {
            await this.$router.go();
          }, 1200);
        }
      }
    },
    editButton(item) {
      this.$router.push({
        name: 'PersonalisedReportAssignButton',
        params: {
          personalizedReportId: item.casePersonalizedReportId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.assign-button {
  margin-right: 30px !important;
}
</style>
