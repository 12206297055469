<template>
  <form ref="form" class="list-filter">
    <div>
      <lims-select
        v-if="isAdminView"
        v-model="filters.statusIds"
        :options="dataSource.statusNameList"
        :translated="true"
        :placeholder="$t('global/placeholder.allStatus')"
      ></lims-select>
      <lims-select
        class="wrap-long-items"
        v-model="filters.snomedMIds"
        :options="dataSource.snomedMNameList"
        :translated="true"
        :placeholder="$t('pages/personalised-report/list/PersonalisedReportFilter/placeholder.snomedM')"
      ></lims-select>
      <lims-select
        class="wrap-long-items"
        v-model="filters.snomedPIds"
        :options="dataSource.snomedPNameList"
        :translated="true"
        :placeholder="$t('pages/personalised-report/list/PersonalisedReportFilter/placeholder.snomedP')"
      ></lims-select>
      <lims-select
        class="wrap-long-items"
        v-model="filters.snomedTIds"
        :options="dataSource.snomedTNameList"
        :translated="true"
        :placeholder="$t('pages/personalised-report/list/PersonalisedReportFilter/placeholder.snomedT')"
      ></lims-select>
    </div>
    <div class="filter-input">
      <lims-select
        v-if="isAdminView"
        v-model="filters.pathologistIds"
        :options="dataSource.pathologistNameList"
        :translated="true"
        :placeholder="$t('pages/personalised-report/list/PersonalisedReportFilter/placeholder.allPathologist')"
      ></lims-select>
      <lims-select
        v-if="!isAdminView"
        v-model="filters.statusIds"
        :options="dataSource.statusNameList"
        :translated="true"
        :placeholder="$t('global/placeholder.allStatus')"
      ></lims-select>
      <md-field class="input-search personalised-report-list">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/personalised-report/list/PersonalisedReportFilter/placeholder.search')"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import { DropdownService } from '@/services';
import { DROPDOWN_SHORT_NAME, PERSONALISED_REPORT_STATUSES_LIST } from '@/core/constants';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    await this.fetchData();
    this.userType = this.$store.getters['auth/userType'];
  },

  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
  },
  data() {
    return {
      userType: null,
      filters: null,
      dataSource: {
        pathologistNameList: [],
        statusNameList: [],
        snomedMNameList: [],
        snomedPNameList: [],
        snomedTNameList: [],
      },
      chipOptions: [],
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.dataSource.statusNameList = PERSONALISED_REPORT_STATUSES_LIST;
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.SNOMED_M,
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.PATHOLOGIST,
      ]);
      this.dataSource.pathologistNameList = this.loadListData(dropdownOptions, DROPDOWN_SHORT_NAME.PATHOLOGIST);
      this.dataSource.snomedMNameList = this.loadListData(dropdownOptions, DROPDOWN_SHORT_NAME.SNOMED_M);
      this.dataSource.snomedPNameList = this.loadListData(dropdownOptions, DROPDOWN_SHORT_NAME.SNOMED_P);
      this.dataSource.snomedTNameList = this.loadListData(dropdownOptions, DROPDOWN_SHORT_NAME.SNOMED_T);
      this.filters = this.value;
    },
    loadListData(dropdownOptions, shortName) {
      // eslint-disable-next-line security/detect-object-injection
      let pathologistList = dropdownOptions[shortName];
      let coverListToMappingWithChipArea = [];
      pathologistList.forEach((pathologistItem) => {
        if (shortName === DROPDOWN_SHORT_NAME.PATHOLOGIST) {
          coverListToMappingWithChipArea.push({
            value: pathologistItem.id,
            label: pathologistItem.text,
          });
        } else {
          coverListToMappingWithChipArea.push({
            value: pathologistItem.fieldItemId,
            label: pathologistItem.fieldItemName,
          });
        }
      });
      return coverListToMappingWithChipArea;
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.personalised-report-list {
  width: 610px !important;
}
</style>
